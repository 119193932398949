
module.exports = {
  
  // The repo name for your Prismic repository (found in the url of your repo: https://stmoriz.prismic.io/)
  prismicRepo: 'ingvarstmoriz',
  // prismicRepo: 'stmoriz',

  // Release ID to build (requires access)
  releaseID: '',

  // access token to access the release
  accessToken: '',

  // Language configuration in your Prismic repository: Settings / Translations & locals
  defaultLanguage: 'en-us',
  langs: ['en-us', 'fr-fr', 'de-ch', 'ar-eg', 'ru', 'fa-ir'],
}
